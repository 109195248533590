import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BGImg from 'gatsby-background-image'
import Img from 'gatsby-image'
import styles from './MusiciansList.module.scss'
import Lightbox from '../Lightbox/Lightbox'
import Link from '../Link/Link'

export default function MusiciansList() {
  const data = useStaticQuery(graphql`
    {
      markdownRemark(
        fileAbsolutePath: { regex: "/content/pages/musicians.md/" }
      ) {
        frontmatter {
          categories {
            category
            musicians {
              firstName
              job
              lastName
              url
              image {
                childImageSharp {
                  id
                  fluid(maxWidth: 250) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const categories = data.markdownRemark.frontmatter.categories

  return (
    <div className={styles.categoriesWrapper}>
      {categories.length
        ? categories.map((c, i) => {
            const { category, musicians } = c
            return (
              <div key={i} className={styles.category}>
                <h2 className={styles.categoryTitle}>{category}</h2>
                <div className={styles.musiciansGrid}>
                  {musicians.length
                    ? musicians.map((m, j) => {
                        const {
                          firstName,
                          lastName,
                          job,
                          url,
                          image: {
                            childImageSharp: { id, fluid }
                          },
                          alt
                        } = m
                        return (
                          <div key={id} className={styles.imageWrapper}>
                            <Lightbox
                              id={id}
                              content={
                                <div className={styles.lightboxContent}>
                                  <Img
                                    fluid={fluid}
                                    alt={alt}
                                    className={styles.lightboxImage}
                                  />
                                  <h2 className={styles.ligthboxName}>
                                    {firstName ? `${firstName} ` : null}
                                    <span className={styles.lastName}>
                                      {lastName}
                                    </span>
                                  </h2>
                                  {job ? (
                                    <p className={styles.job}>{job}</p>
                                  ) : null}
                                  {url ? (
                                    <Link className={styles.link} to={url}>
                                      More
                                    </Link>
                                  ) : null}
                                </div>
                              }
                            >
                              <div className={styles.thumbnailContent}>
                                {firstName ? (
                                  <p className={styles.firstName}>
                                    {firstName}
                                  </p>
                                ) : null}
                                <p className={styles.lastName}>{lastName}</p>
                              </div>
                              <div>
                                <BGImg
                                  fluid={fluid}
                                  className={[
                                    styles.image,
                                    `atIndex${id}`
                                  ].join(' ')}
                                ></BGImg>
                              </div>
                            </Lightbox>
                          </div>
                        )
                      })
                    : null}
                </div>
              </div>
            )
          })
        : null}
    </div>
  )
}
