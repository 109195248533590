import React, { useState } from 'react'
import styles from './LiveTabs.module.scss'

import VideosList from '../VideosList/VideosList'
import PhotosGallery from '../PhotosGallery/PhotosGallery'
import MusiciansList from '../MusiciansList/MusiciansList'

export default function LiveTabs() {
  const [currentTab, setCurrentTab] = useState('videos')
  const setTabToVideo = () => {
    if (currentTab !== 'videos') {
      setCurrentTab('videos')
    }
  }
  const setTabToPhotos = () => {
    if (currentTab !== 'photos') {
      setCurrentTab('photos')
    }
  }
  const setTabToMusicians = () => {
    if (currentTab !== 'musicians') {
      setCurrentTab('musicians')
    }
  }

  return (
    <div className={styles.tabsWrapper}>
      <div className={styles.tabsMenu}>
        <button
          className={
            currentTab === 'videos'
              ? [styles.tabButton, styles.active].join(' ')
              : styles.tabButton
          }
          onClick={setTabToVideo}
        >
          Videos
        </button>
        <button
          className={
            currentTab === 'photos'
              ? [styles.tabButton, styles.active].join(' ')
              : styles.tabButton
          }
          onClick={setTabToPhotos}
        >
          Photos
        </button>
        <button
          className={
            currentTab === 'musicians'
              ? [styles.tabButton, styles.active].join(' ')
              : styles.tabButton
          }
          onClick={setTabToMusicians}
        >
          Musicians
        </button>
      </div>
      <div style={currentTab !== 'videos' ? { display: 'none' } : {}}>
        <VideosList />
      </div>
      <div style={currentTab !== 'photos' ? { display: 'none' } : {}}>
        <PhotosGallery />
      </div>
      <div style={currentTab !== 'musicians' ? { display: 'none' } : {}}>
        <MusiciansList />
      </div>
    </div>
  )
}
