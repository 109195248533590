import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import BGImg from 'gatsby-background-image'
import styles from './PhotosGallery.module.scss'
import Lightbox from '../Lightbox/Lightbox'

export default function PhotosGallery() {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "//content/photos//" } }
        sort: { order: DESC, fields: frontmatter___date }
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              date
              photos {
                alt
                legend
                image {
                  id
                  childImageSharp {
                    fluid(maxWidth: 1024) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const albums = data.allMarkdownRemark.edges
  if (albums.length) {
    return (
      <>
        {albums.map(album => {
          const albumID = album.node.id
          const albumTitle = album.node.frontmatter.title
          const albumPhotos = album.node.frontmatter.photos
          if (albumPhotos.length && albumTitle) {
            return (
              <section key={albumID} className={styles.albumWrapper}>
                <h2 className={styles.albumTitle}>{albumTitle}</h2>
                <div className={styles.photosGrid}>
                  {albumPhotos.length
                    ? albumPhotos.map(photo => {
                        const photoID = photo.image.id
                        const image = photo.image.childImageSharp.fluid
                        const alt = photo.alt
                        const legend = photo.legend
                        return (
                          <div key={photoID} className={styles.photoWrapper}>
                            <Lightbox
                              id={photoID}
                              content={
                                <div className={styles.contentWrapper}>
                                  <Img
                                    fluid={image}
                                    alt={alt}
                                    style={{ marginTop: '1rem' }}
                                    imgStyle={{ objectFit: 'contain' }}
                                  ></Img>
                                  {legend ? (
                                    <p className={styles.legend}>{legend}</p>
                                  ) : null}
                                </div>
                              }
                            >
                              <div>
                                <BGImg
                                  fluid={image}
                                  className={[
                                    styles.photo,
                                    `atIndex${photoID}`
                                  ].join(' ')}
                                />
                              </div>
                            </Lightbox>
                          </div>
                        )
                      })
                    : null}
                </div>
              </section>
            )
          } else {
            return null
          }
        })}
      </>
    )
  } else {
    return null
  }
}
