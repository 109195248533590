import React from 'react'
import LiveTabs from '../components/LiveTabs/LiveTabs'
import SEO from '../components/SEO/SEO'

export default function LivePage() {
  return (
    <>
      <SEO title="Live" route="/live"></SEO>
      <LiveTabs />
    </>
  )
}
