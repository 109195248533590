import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styles from './VideosList.module.scss'

export default function VideosList() {
  const data = useStaticQuery(graphql`
    {
      markdownRemark(fileAbsolutePath: { regex: "/content/pages/videos.md/" }) {
        id
        frontmatter {
          videos {
            title
            url
          }
        }
      }
    }
  `)
  const videos = data.markdownRemark.frontmatter.videos
  return (
    <div className={styles.videosList}>
      {videos.map((video, index) => {
        return (
          <div key={index} className={styles.videoWrapper}>
            <div
              style={{
                position: 'relative',
                paddingTop: '56.25%'
              }}
            >
              <iframe
                src={`https://www.youtube.com/embed/${video.url}`}
                title={video.title}
                width="100%"
                height="100%"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  backgroundColor: '#080808'
                }}
              ></iframe>
            </div>
          </div>
        )
      })}
    </div>
  )
}
